import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import './Footer.css'
import './Popup.css'
import fb from '../asset/fb.png'
import mail from '../asset/mail.png'
import legal from '../asset/legal.jpg'
import btnlegal from '../asset/legal.png'

class Footer extends Component {
    state = {
        openLegal: false,
    };

    onOpenModal = (e) => {
        e.preventDefault();
        this.setState({ openLegal: true });
    };

    onCloseModal = () => {
        this.setState({ openLegal: false });
    };

    render() {
        const { openLegal } = this.state;
        return(
            <div className="footer">
                <div className="content">
                    <div className="text">Pour nous contacter rejoignez le groupe Facebook ou envoyez nous un mail</div>
                    <a href="https://www.facebook.com/na.re.96930" alt="fb"><img src={fb} alt="fb"/></a>
                    <a href="mailto:navigationauto.raonletape@gmail.com" alt="mail"><img src={mail} alt="mail"/></a>
                    <a href="#" onClick={this.onOpenModal}><img src={btnlegal} alt="mail"/></a>
                </div>
                <div className="vala-legal">
                    <Modal open={openLegal} onClose={this.onCloseModal}>
                        <img class="modal-img" src={legal} alt="legal"/>
                    </Modal>
                </div>
            </div>
        )
    }
}

export default Footer