import React, { Component } from 'react';
import './Title.css';
import logoNare from '../asset/logo-nare.png';
import porsche from '../asset/scroll/porsche.png';
import r5 from '../asset/scroll/r5.png';

import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'

import carousel1 from "../asset/carouselHome/carousel1.jpg";
import carousel2 from "../asset/carouselHome/carousel2.jpg";
import carousel3 from "../asset/carouselHome/carousel3.jpg";
import carousel4 from "../asset/carouselHome/carousel4.jpg";
import carousel5 from "../asset/carouselHome/carousel5.jpg";
import carousel6 from "../asset/carouselHome/carousel6.jpg";
import carousel7 from "../asset/carouselHome/carousel7.jpg";
import carousel8 from "../asset/carouselHome/carousel8.jpg";
import carousel9 from "../asset/carouselHome/carousel9.jpg";
import carousel10 from "../asset/carouselHome/carousel10.jpg";

export const EmblaCarousel = () => {
    const [emblaRef] = useEmblaCarousel({ loop: false }, [Autoplay()])
    return (
        <div className="embla" ref={emblaRef}>
            <div className="embla__container">
                <div className="embla__slide"><img src={carousel1}/></div>
                <div className="embla__slide"><img src={carousel2}/></div>
                <div className="embla__slide"><img src={carousel3}/></div>
                <div className="embla__slide"><img src={carousel4}/></div>
                <div className="embla__slide"><img src={carousel5}/></div>
                <div className="embla__slide"><img src={carousel6}/></div>
                <div className="embla__slide"><img src={carousel7}/></div>
                <div className="embla__slide"><img src={carousel8}/></div>
                <div className="embla__slide"><img src={carousel9}/></div>
                <div className="embla__slide"><img src={carousel10}/></div>
            </div>
        </div>
    )
}


class Title extends Component {
    render() {
        return(
            <div className="nare-title">
                <EmblaCarousel></EmblaCarousel>
            </div>
        )
    }
}

export default Title