import React from 'react';
import Modal from 'react-responsive-modal';
import "./Popup.css"


import affiche from "../asset/affiche/sapins.jpg";

export default class Centered extends React.Component {
    state = {
        open: true,
    };

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    render() {
        if (this.props.popup === false)
            this.state.open = false;
        const { open } = this.state;
        return (
            <div className="modal">
                <Modal open={open} onClose={this.onCloseModal}>
                    <div className="popup-img">
                        <img src={affiche} />
                    </div>
                </Modal>
            </div>
        );
    }
}