import React, { Component } from 'react';

import Request from './ApiClient';
import { Link } from 'react-router-dom'

class EventList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            events: this.getList(this.props.page)
        }
    }

    getList(page) {
        this.setState({page: page});
        Request.get("/articleList/2?_format=json&page=" + page, '').then(
            response => {
                this.setState({events: response});
            }
        )
    }

    rawMarkup(markup){
        return { __html: markup };
    }

    eventView(event) {

        var htmlInput = event.field_resume[0].value;
        var imageTeaser = event.field_image_teaser[0];
        var date  = event.created[0].value;
        var nid  = event.nid[0].value;
        const path = "/article/" + nid;

        if (imageTeaser === undefined || imageTeaser.url === undefined) {
            imageTeaser = '';
        }
        else {
            imageTeaser = <img alt={imageTeaser.alt != undefined ? imageTeaser.alt : 'image'} src={imageTeaser.url} />
        }

        date = new Date(date);

        return(
            <div className="article-teaser">
                {imageTeaser}
                <div className="article-content">
                    <h3>{event.title[0].value}</h3>
                    <div dangerouslySetInnerHTML={this.rawMarkup(htmlInput)}></div>
                </div>
                <div className="link-more">
                    <div className="event-date">
                        {date.toLocaleDateString()}
                    </div>
                    <Link to={path}>Voir plus</Link>
                </div>
            </div>
        );
    }

    render() {
        var output = [];
        var i = 0;

        if (this.state.page !== this.props.page)
            this.getList(this.props.page);

        for (var event in this.state.events) {
            i++;
            output.push(
                <div className="event">
                    {this.eventView(this.state.events[event])}
                </div>
            );
            if (i < this.state.events.length) {
                output.push(<div className="checkered"/>);
            }
        }

        return(output);
    }
}

export default EventList;