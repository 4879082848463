import React, { Component } from 'react';
import logo from '../asset/logo-nare.png';
import './Menu.css';
import Logo from './Logo'
import { goToTop } from 'react-scrollable-anchor'
import { Link } from 'react-router-dom'

class MenuPassed extends Component {

    handleLogoClick() {
        goToTop()
    }

    render() {
        return (
            <div className="nare-menu-header-wrapper">
                <div className="nare-menu-header">
                    <div className="nare-nav">
                        <a className="logo-link" href="/passed-event"><Logo src={logo} className="nare-logo" alt="logo" onClick={this.handleLogoClick} /></a>
                        <a href="https://formation.nare.club">
                            <span className={"brown"}>JOURNÉE DE FORMATION A LA NAVIGATION NARE 2020</span>
                        </a>
                        <a href="https://nare.club">RETOUR ACCUEIL</a>
                    </div>
                </div>
            </div>
        )
    }
}

export default MenuPassed