import React, { Component } from 'react';
import Pager from 'react-pager';

import Menu from './section/Menu';
import './Event.css';
import EventList from './EventList';
import Request from './ApiClient';

class Event extends Component {

    constructor() {
        super();
        this.state = {
            total:       0,
            current:     0,
            visiblePage: 3,
        }

        this.handlePageChanged = this.handlePageChanged.bind(this);
    }

    componentDidMount() {
        Request.get("/articleList-all/2?_format=json", '').then(
            response => {
                this.setState({total: Math.ceil(response.length/5)});
            }
        )
    }

    handlePageChanged(newPage) {
        this.setState({ current : newPage });
    }

    render() {
        return(
            <div className="nare">
                <div className="header">
                    <Menu />
                </div>
                <div className="content">

                    <h2>Nos participations aux manifestations</h2>

                    <EventList page={this.state.current} />

                    <Pager
                        total={this.state.total}
                        current={this.state.current}
                        visiblePages={this.state.visiblePage}
                        titles={{ first: '<|', last: '>|' }}
                        className="pagination"
                        onPageChanged={this.handlePageChanged}
                    />

                </div>
            </div>
        );
    }
}

export default Event