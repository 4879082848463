import React, { Component } from 'react';
import logo from '../asset/logo-nare.png';
import logo_cup from '../asset/logo-nare-cup.jpg';
import './Menu.css';
import Logo from './Logo'
import { goToTop } from 'react-scrollable-anchor'
import { Link } from 'react-router-dom'
import roadbookPDF from '../asset/pdf/explication-roadbook-NARE.pdf'

class Menu extends Component {

    handleLogoClick() {
        goToTop()
    }

    render() {
        return (
            <div className="nare-menu-header-wrapper">
                <div className="nare-menu-header">
                    <div className="nare-nav">
                        <a href="/home"><Logo src={logo} className="nare-logo" alt="nare logo" onClick={this.handleLogoClick} /></a>
                        <a href="https://cup.nare.club"><Logo src={logo_cup} className="nare-logo" alt="nare cup logo" /></a>
                        <a href="https://bds.nare.club">
                            <span className={"light"}>Navigation Automobile du</span><br/>
                            <span className={"orange"}> Ban de Sapt</span></a>
                        <a href="https://vosges-alsace.nare.club">
                            <span className={"light"}>Navigation Automobile</span><br/>
                            <span className={"green"}> Vosges-Alsace</span></a>
                        <a href="https://bdl.nare.club">
                            <span className={"light"}>Navigation Automobile de</span><br/>
                            <span className={"yellow"}> Ban de Laveline</span></a>
                        <a href="https://gtl.nare.club">
                            <span className={"light"}>Navigation historique du</span><br/>
                            <span className={"beige"}> GT Legends</span></a>
                        <a href="https://sapins.nare.club">
                            <span className={"light"}>Navigation Automobile des</span><br/>
                            <span className={"green"}> Sapins</span></a>
                        <a href="https://octobre-rose.nare.club">
                            <span className={"light"}>Navigation Automobile</span><br/>
                            <span className={"rose"}>Octobre Rose</span>
                        </a>
                        <a href={roadbookPDF}>Explication <span className={"light"}>d'une épreuve de navigation</span></a>
                        <a href="https://cup.nare.club">NARE CUP</a>
                        <a href="https://nare.club">RETOUR ACCUEIL</a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Menu
