import React, { Component } from 'react';
import Request from './ApiClient';
import Menu from './section/Menu';

import './Article.css'


class Article extends Component {

    constructor(props) {
        super(props);
        this.state = {
            articleFull: []
        }

        this.goBack = this.goBack.bind(this);
    }

    componentDidMount() {
        var nid = this.props.match.params.nid;

        Request.get("/article/" + nid + "?_format=json", '').then(
            response => {
                this.setState({articleFull: response});
            }
        )
    }

    goBack(){
        this.props.history.goBack();
    }

    render() {
        var articleFull  = this.state.articleFull[0];
        var sections = new Array;
        var title = '';

        if(articleFull != undefined) {
            title = articleFull.title[0].value
            for (var sectionId in articleFull.field_section) {
                sections.push(<Paragraph entity_id={articleFull.field_section[sectionId].target_id} />);
            }
        }

        return (
            <div className="nare">
                <div className="header">
                    <Menu />
                </div>
                <div className="content">
                    <article>
                        <a className="return-link" href="#" onClick={this.goBack} > {"<"}--- Retour aux articles</a>
                        <h2>{title}</h2>
                        {sections}
                        <a className="return-link bottom" href="#" onClick={this.goBack} > {"<"}--- Retour aux articles</a>
                    </article>
                </div>
            </div>
        )
    }
}

class Paragraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paragraph: []
        }
    }

    componentDidMount() {
        var id = this.props.entity_id;

        Request.get("/paragraph/" + id + "?_format=json", '').then(
            response => {
                this.setState({paragraph: response[0]});
                return null
            }
        )
    }

    viewImage() {
        var paragraph = this.state.paragraph;
        var img = [];

        for (var field_image_id in paragraph.field_image) {
            var field_image = paragraph.field_image[field_image_id];
            img.push(<img src={field_image.url} alt={field_image.alt}/>)
        }

        return img;
    }

    viewText() {
        var paragraph = this.state.paragraph;
        var markup = paragraph.field_texte[0].value;
        var html = {__html: markup};

        return(
            <div dangerouslySetInnerHTML={html}></div>
        )
    }

    render() {

        var paragraph = this.state.paragraph;
        var sectionType = '';
        var section = '';
        var classes = 'section';

        if (this.state.paragraph != undefined) {
            if (paragraph.type != undefined) {
                sectionType = paragraph.type[0].target_id;
                if (sectionType === 'section_image') {
                    section = this.viewImage();
                    classes = classes + "  section-image";
                } else {
                    section = this.viewText();
                    classes = classes + " section-text";
                }
            }
        }

        return(
            <div className={classes}>
                {section}
            </div>
        )
    }
}

export default Article