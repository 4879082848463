import React from 'react';
import "./GalleryNavBar.css";

import plaque_rose18 from "./../asset/plaque/plaque_rose_2018.png";
import plaque_sapin19 from "./../asset/plaque/plaque_sapin_2019.png";
import plaque_rose19 from "./../asset/plaque/plaque_or_19.png";
import plaque_formation20 from "./../asset/plaque/plaque_formation20.png";
import plaque_sapin20 from "./../asset/plaque/plaque_sapin_2020.png"
import plaque_rose20 from "./../asset/plaque/plaque_or_2020.png"
import plaque_sapin21 from "./../asset/plaque/plaque_sapin_2021.png"
import plaque_bdl22 from "./../asset/plaque/plaque_bdl_2022.png"
import plaque_bdl23 from "./../asset/plaque/plaque_bdl_2023.png"
import plaque_sapin22 from "./../asset/plaque/plaque_sapin_2022.png"
import plaque_6h22 from "./../asset/plaque/plaque_6h22.png"
import plaque_6h23 from "./../asset/plaque/plaque_6h23.png"
import plaque_leclerc22 from "./../asset/plaque/plaque_leclerc22.png"
import plaque_leclerc23 from "./../asset/plaque/plaque_leclerc23.png"
import plaque_rose22 from "./../asset/plaque/plaque_or_2022.png"
import plaque_rose23 from "./../asset/plaque/plaque_or_2023.png"
import plaque_sapins23 from "./../asset/plaque/plaque_sapins_2023.png"
import plaque_gtl23 from "./../asset/plaque/plaque_gtl_2023.png"
import plaque_raon22 from "./../asset/plaque/plaque_raon22.png"
import plaque_vosges22 from "./../asset/plaque/plaque_vosges22.png"
import plaque_bds24 from "./../asset/plaque/plaque_bds_2024.png"
import plaque_va24 from "./../asset/plaque/plaque_va_2024.png"
import plaque_bdl24 from "./../asset/plaque/plaque_bdl_2024.png"
import plaque_gtl24 from "./../asset/plaque/plaque_gtl_2024.png"


const navGalleryItems = {
  "rose18": {
    "imageSrc": plaque_rose18,
    "label": "Octobre rose 2018",
    "url": "https://www.facebook.com/media/set/?set=a.240397990164235&type=3",
  },
  "sapin19": {
    "imageSrc": plaque_sapin19,
    "label": "Sapins 2019",
    "url": "https://www.facebook.com/media/set/?set=a.347196382817728&type=3",
  },
  "rose19": {
    "imageSrc": plaque_rose19,
    "label": "Octobre rose 2019",
    "url": "https://www.facebook.com/media/set/?set=a.444473549756677&type=3",
  },
  "formation20": {
    "imageSrc": plaque_formation20,
    "label": "Formation 2020",
    "url": "https://www.facebook.com/media/set/?set=a.550257402511624&type=3",
  },
  "sapin20": {
    "imageSrc": plaque_sapin20,
    "label": "Sapins 2020",
    "url": "https://www.facebook.com/media/set/?set=a.687183815485648&type=3",
  },
  "rose20": {
    "imageSrc": plaque_rose20,
    "label": "Octobre rose 2020",
    "url": "https://www.facebook.com/media/set/?set=a.703768527160510&type=3",
  },
  "sapin21": {
    "imageSrc": plaque_sapin21,
    "label": "Sapins 2021",
    "url": "https://www.facebook.com/media/set/?set=a.932093697661324&type=3",
  },
  "bdl22": {
    "imageSrc": plaque_bdl22,
    "label": "Ban de Laveline 2022",
    "url": "https://www.facebook.com/media/set/?set=a.990072911863402&type=3",
  },
  "sapin22": {
    "imageSrc": plaque_sapin22,
    "label": "Sapins 2022",
    "url": "https://www.facebook.com/media/set/?set=a.1048942162643143&type=3",
  },
  "baladeraon22": {
    "imageSrc": plaque_raon22,
    "label": "Balade Raonnaise 2022",
    "url": "https://www.facebook.com/media/set/?set=a.172566428536897&type=3",
  },
  "6h22": {
    "imageSrc": plaque_6h22,
    "label": "6h Auto-Raon 2022",
    "url": "https://www.facebook.com/media/set/?set=a.184344700692403&type=3",
  },
  "leclerc22": {
    "imageSrc": plaque_leclerc22,
    "label": "E.Leclerc 2022",
    "url": "https://www.facebook.com/media/set/?set=a.1149171889286836&type=3",
  },
  "baladevosges22": {
    "imageSrc": plaque_vosges22,
    "label": "Balade Vosges Alsace 2022",
    "url": "https://www.facebook.com/media/set/?set=a.200650229061850&type=3",
  },
  "rose22": {
    "imageSrc": plaque_rose22,
    "label": "Octobre rose 2022",
    "url": "https://www.facebook.com/media/set/?set=a.1180468002823891&type=3",
  },
  "bdl23": {
    "imageSrc": plaque_bdl23,
    "label": "Ban de Laveline 2023",
    "url": "https://www.facebook.com/media/set/?set=a.1272952413575449&type=3",
  },
  "sapins23": {
    "imageSrc": plaque_sapins23,
    "label": "Sapins 2023",
    "url": "https://www.facebook.com/media/set/?vanity=na.re.96930&set=a.1295747584629265",
  },
  "baladegtl23": {
    "imageSrc": plaque_gtl23,
    "label": "Balade GTLEGENDS 2023",
    "url": "https://www.facebook.com/media/set/?set=a.300482995745239&type=3",
  },
  "6h23": {
    "imageSrc": plaque_6h23,
    "label": "6h Auto-Raon 2023",
    "url": "https://www.facebook.com/media/set/?set=a.304528485340690&type=3",
  },
  "leclerc23": {
    "imageSrc": plaque_leclerc23,
    "label": "E.Leclerc 2023",
    "url": "https://www.facebook.com/media/set/?set=a.1365124834358206&type=3",
  },
  "rose23": {
    "imageSrc": plaque_rose23,
    "label": "Octobre rose 2023",
    "url": "https://www.facebook.com/media/set/?set=a.1382939282576761&type=3",
  },
  "bds24": {
    "imageSrc": plaque_bds24,
    "label": "Ban de Sapt 2024",
    "url": "https://www.facebook.com/media/set/?vanity=na.re.96930&set=a.1465998400937515",
  },
  "va24": {
    "imageSrc": plaque_va24,
    "label": "Vosges Alsace 2024",
    "url": "https://www.facebook.com/media/set/?set=a.464121792714691&type=3",
  },
  "bdl24": {
    "imageSrc": plaque_bdl24,
    "label": "Ban de Laveline 2024",
    "url": "https://www.facebook.com/media/set/?set=a.1507020946835260&type=3",
  },
  "gtl24": {
    "imageSrc": plaque_gtl24,
    "label": "GT Legends 2024",
    "url": "https://www.facebook.com/media/set/?set=a.506698815123655&type=3",
  },
}

const GalleryNavBar = () => {

  return (
    <nav className="gallery-tab">
      <ul>
        {(Object.entries(navGalleryItems).reverse()).map((item, key) => (
          <a href={item[1].url} className={'tab-item'}>
            <img alt={`Image d'un panneau representant la navigation ${item[1].label}`} src={item[1].imageSrc}/>
            <div>{item[1].label}</div>
          </a>
        ))}
      </ul>
    </nav>
  )
}

export default GalleryNavBar;
