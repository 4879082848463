import React, { Component } from 'react';
import './App.css';
import Menu from './section/Menu';
import Title from './section/Title';
import Footer from './section/Footer';
import { ParallaxProvider } from 'react-scroll-parallax';
import ScrollableAnchor from 'react-scrollable-anchor'
import { configureAnchors } from 'react-scrollable-anchor'
import ArticleBlock from "./section/ArticleBlock";
import PopupAffiche from "./section/Popup";

configureAnchors({offset: -100, scrollDuration: 1000})

class App extends Component {

    state = {
        popup: true,
    };

    componentDidMount() {
        if (this.props.match.path === "/home") {
            this.setState({popup: false});
        }
    }

    render() {
        return (
            <ParallaxProvider>
                <div className="nare">
                    {/*<PopupAffiche popup={this.state.popup} />*/}
                    <ScrollableAnchor id={'header'}>
                        <div className="header">
                            <Menu />
                        </div>
                    </ScrollableAnchor>
                    <div className="content">
                        <Title />

                        <div className="checkered-main"/>

                        <ArticleBlock />

                    </div>
                    <ScrollableAnchor id={'joinus'}>
                        <Footer />
                    </ScrollableAnchor>
                </div>
            </ParallaxProvider>
        );
    }
}

export default App;
