import React, { Component } from 'react';
import "./Roadbook.css"
import Menu from './section/Menu';
import Footer from './section/Footer';
import '../node_modules/react-image-gallery/styles/css/image-gallery.css'

const content =
        <div className="contact">
            <div className="text">
                <h2>Contact</h2>
                <div className="section">
                    <p>Mail : <a href="mailto:navigationauto.raonletape@gmail.com">navigationauto.raonletape@gmail.com</a></p>

                    <p>Téléphone : 06 79 23 09 14 ou +33 6 79 23 09 14</p>

                    <p>Facebook : <a href="https://www.facebook.com/na.re.96930">NA RE(Nare Club)</a></p>

                    <p>YouTube : <a href="https://www.youtube.com/channel/UCa1LBJNLWzH9tz08H3PegnA">NARE CLUB</a></p>
                </div>
            </div>
        </div>


class Roadbook extends Component {
    render() {
        return (
            <div className="nare">
                <div className="header">
                    <Menu />
                </div>
                <div className="body content">
                    { content }
                </div>
                <Footer />
            </div>
        );
    }
}

export default Roadbook;
