import React, { Component } from 'react';
import "./Result.css";
import Menu from './section/Menu';
import Footer from './section/Footer';
import { Document, Page } from 'react-pdf';

import result_cup from "./asset/results/result_cup.png";

import logo_nare from "./asset/logo-nare.png";
import plaque_rose18 from "./asset/plaque/plaque_rose_2018.png";
import plaque_rose19 from "./asset/plaque/plaque_or_19.png";
import plaque_formation20 from "./asset/plaque/plaque_formation20.png";
import plaque_sapin from "./asset/plaque/plaque_sapin_2019.png";
import plaque_sapin20 from "./asset/plaque/plaque_sapin_2020.png"
import plaque_rose20 from "./asset/plaque/plaque_or_2020.png"
import plaque_sapins21 from "./asset/plaque/plaque_sapin_2021.png"
import plaque_bdl22 from "./asset/plaque/plaque_bdl_2022.png"
import plaque_sapins22   from "./asset/plaque/plaque_sapin_2022.png"
import plaque_6h22   from "./asset/plaque/plaque_6h22.png"
import plaque_6h23   from "./asset/plaque/plaque_6h23.png"
import plaque_leclerc22   from "./asset/plaque/plaque_leclerc22.png"
import plaque_leclerc23   from "./asset/plaque/plaque_leclerc23.png"
import plaque_rose22   from "./asset/plaque/plaque_or_2022.png"
import plaque_cup22   from "./asset/plaque/plaque_cup.jpg"
import plaque_cup23   from "./asset/plaque/plaque_cup_2023.jpg"
import plaque_bdl23 from "./asset/plaque/plaque_bdl_2023.png"
import plaque_sapins23 from "./asset/plaque/plaque_sapins_2023.png"
import plaque_rose23 from "./asset/plaque/plaque_or_2023.png"
import plaque_bds24 from "./asset/plaque/plaque_bds_2024.png"
import plaque_va24 from "./asset/plaque/plaque_va_2024.png"
import plaque_bdl24 from "./asset/plaque/plaque_bdl_2024.png"
import plaque_gtl24 from "./asset/plaque/plaque_gtl_2024.png"

import result_palmares from "./asset/results/general/palmares.jpg"

import result_sapins_2019 from "./asset/results/resultats_sapin2019.pdf"
import result_sapins_2020 from "./asset/results/resultats_sapin2020.jpg"
import result_sapins_2023 from "./asset/results/resultats_sapin2023.jpg"

import result_or19 from "./asset/results/resultats_or2019.png";
import result_or_20 from "./asset/results/resultats_or2020.jpg";
import or22 from "./asset/results/resultats_or2022.jpg"
import result_or_23 from "./asset/results/resultats_or2023.jpg"

import result_general1 from "./asset/results/general/result1.png";
import result_general2 from "./asset/results/general/result2.png";
import result_general3 from "./asset/results/general/result3.png";
import result_general4 from "./asset/results/general/result4.png";
import result_general5 from "./asset/results/general/result5.png";
import result_general6 from "./asset/results/general/result6.png";

import result_modern1 from "./asset/results/modern/result1.png";
import result_modern2 from "./asset/results/modern/result2.png";
import result_modern3 from "./asset/results/modern/result3.png";
import result_modern4 from "./asset/results/modern/result4.png";

import result_classic1 from "./asset/results/classic/result1.png";
import result_classic2 from "./asset/results/classic/result2.png";
import result_classic3 from "./asset/results/classic/result3.png";

import result_women from "./asset/results/women/result.png";


import leclerc23 from "./asset/results/result_leclerc23.jpg"
import leclerc22 from "./asset/results/result_leclerc22.jpg"

import result_formation20 from "./asset/results/result_formation20.png";
import result_sapin21 from "./asset/results/resultats_sapin2021.jpg"
import result_bdl22 from "./asset/results/resultats_bdl2022.jpg"
import result_sapin22 from "./asset/results/resultats_sapin2022.jpg"
import sixh22 from "./asset/results/resultats_6h22.jpg"
import cup22 from "./asset/results/result_nare_cup.jpg"
import cup23_1 from "./asset/results/result_nare_cup2023-1.jpg"
import cup23_2 from "./asset/results/result_nare_cup2023-2.jpg"
import result_bdl23 from "./asset/results/result_bdl2023.jpg"
import result_six23 from "./asset/results/result_6h2023.jpg"

import result_bds_24 from "./asset/results/result_bds_2024.jpg"
import result_va_24 from "./asset/results/result_va_2024.jpg"
import result_bdl_24 from "./asset/results/result_bdl24.jpg"
import result_gtl_24 from "./asset/results/result_gtl24.jpg"

const palmares = (
    <div className="result">
        <div className="title-gogole">
            Cliquez sur la plaque d'une navigation ci-dessus pour les résultats détaillés
        </div>
        <div className="title">
            Palmares Général des navigations du NARE
        </div>
        <div className="text">
            Merci à tous les participants
        </div>
        <img alt="tableau palmares nare" src={result_palmares}/>
    </div>
);

const cup2023 = (
    <div className="result">
        <div className="title">
            Résultats de la NARE CUP 2023
        </div>
        <div className="text">
            Merci à tous pour votre participation. Félicitations aux vainqueurs !
        </div>
        <img alt="nare cup 2023" src={cup23_1}/>
        <img alt="nare cup 2023" src={cup23_2}/>
    </div>
);

const cup2022 = (
    <div className="result">
        <div className="title">
            Résultats de la NARE CUP 2022
        </div>
        <div className="text">
            Merci à tous pour votre participation. Félicitations aux vainqueurs !
        </div>
        <img alt="nare cup 2022" src={cup22}/>
    </div>
);

const gtl2024 = (
    <div className="result">
        <div className="title">
            Résultats de la NAVIGATION HISTORIQUE DU GT LEGENS 2024
        </div>
        <div className="text">
            Merci à tous pour votre participation et cette belle journée passée ensemble. Félicitations aux vainqueurs !
        </div>
        <img alt="GT Legendsd 2024" src={result_gtl_24}/>
    </div>
);

const bdl2024 = (
    <div className="result">
        <div className="title">
            Résultats de la NAVIGATION AUTOMOBILE BAN DE LAVELINE 2024
        </div>
        <div className="text">
            Merci à tous pour votre participation et cette belle journée passée ensemble. Félicitations aux vainqueurs !
        </div>
        <img alt="Ban de Laveline 2024" src={result_bdl_24}/>
    </div>
);

const va2024 = (
    <div className="result">
        <div className="title">
            Résultats de la NAVIGATION AUTOMOBILE VOSGES ALSACE 2024
        </div>
        <div className="text">
            Merci à tous pour votre participation et cette belle journée passée ensemble. Félicitations aux vainqueurs !
        </div>
        <img alt="Vosges Alsace 2024" src={result_va_24}/>
    </div>
);

const bds2024 = (
    <div className="result">
        <div className="title">
            Résultats de la NAVIGATION AUTOMOBILE DE BAN DE SAPT 2024
        </div>
        <div className="text">
            Merci à tous pour votre participation et cette belle journée passée ensemble. Félicitations aux vainqueurs !
        </div>
        <img alt="Ban de sapt 2024" src={result_bds_24}/>
    </div>
);

const or2023 = (
    <div className="result">
        <div className="title">
            Résultats de la NAVIGATION AUTOMOBILE OCTOBRE ROSE 2023
        </div>
        <div className="text">
            Merci à tous pour votre participation et cette belle journée passée ensemble. Félicitations aux vainqueurs !
        </div>
        <img alt="octobre rose 2022" src={result_or_23}/>
    </div>
);

const or2022 = (
    <div className="result">
        <div className="title">
            Résultats de la NAVIGATION AUTOMOBILE OCTOBRE ROSE 2022
        </div>
        <div className="text">
            Merci à tous pour votre participation et cette belle journée passée ensemble. Félicitations aux vainqueurs !
        </div>
        <img alt="octobre rose 2022" src={or22}/>
    </div>
);

const leclerc2023 = (
    <div className="result">
        <div className="title">
            Résultats de la NAVIGATION AUTOMOBILE E.LECLERC 2023
        </div>
        <div className="text">
            Merci à tous pour votre participation et cette belle journée passée ensemble. Félicitations aux vainqueurs !
        </div>
        <img alt="leclerc 2023" src={leclerc23}/>
    </div>
);

const leclerc2022 = (
    <div className="result">
        <div className="title">
            Résultats de la NAVIGATION AUTOMOBILE E.LECLERC 2022
        </div>
        <div className="text">
            Merci à tous pour votre participation et cette belle journée passée ensemble. Félicitations aux vainqueurs !
        </div>
        <img alt="leclerc 2022" src={leclerc22}/>
    </div>
);

const sixh2022 = (
    <div className="result">
        <div className="title">
            Résultats de la NAVIGATION AUTOMOBILE des 6H AUTO-RAON 2022
        </div>
        <div className="text">
            Merci à tous pour votre participation et cette belle journée passée ensemble. Félicitations aux vainqueurs !
        </div>
        <img alt="palmares 6h 2022" src={sixh22}/>
    </div>
);

const sixh2023 = (
    <div className="result">
        <div className="title">
            Résultats de la NAVIGATION AUTOMOBILE des 6H AUTO-RAON 2023
        </div>
        <div className="text">
            Merci à tous pour votre participation et cette belle journée passée ensemble. Félicitations aux vainqueurs !
        </div>
        <img alt="palmares 6h 2023" src={result_six23}/>
    </div>
);

const sapins2023 = (
    <div className="result">
        <div className="title">
            Résultats de la NAVIGATION AUTOMOBILE des sapins 2023
        </div>
        <div className="text">
            Merci à tous pour votre participation et cette belle journée passée ensemble. Félicitations aux vainqueurs !
        </div>
        <img alt="palmares nare sapins 2023" src={result_sapins_2023}/>
    </div>
);

const sapins2022 = (
    <div className="result">
        <div className="title">
            Résultats de la NAVIGATION AUTOMOBILE des sapins 2022
        </div>
        <div className="text">
            Merci à tous pour votre participation et cette belle journée passée ensemble. Félicitations aux vainqueurs !
        </div>
        <img alt="palmares nare sapins 2022" src={result_sapin22}/>
    </div>
);

const bdl2023 = (
    <div className="result">
        <div className="title">
            Résultats de la NAVIGATION AUTOMOBILE de Ban de Laveline 2023
        </div>
        <div className="text">
            Merci à tous pour votre participation et cette belle journée passée ensemble. Félicitations aux vainqueurs !
        </div>
        <img alt="palmares nare ban de laveline 2023" src={result_bdl23}/>
    </div>
);

const bdl2022 = (
    <div className="result">
        <div className="title">
            Résultats de la NAVIGATION AUTOMOBILE de Ban de Laveline 2022
        </div>
        <div className="text">
            Merci à tous pour votre participation et cette belle journée passée ensemble. Félicitations aux vainqueurs !
        </div>
        <img alt="palmares nare ban de laveline 2022" src={result_bdl22}/>
    </div>
);

const sapins2021 = (
    <div className="result">
        <div className="title">
            Résultats de la NAVIGATION AUTOMOBILE des Sapins 2021
        </div>
        <div className="text">
            Merci à tous pour votre participation et cette belle journée passée ensemble. Félicitations aux vainqueurs !
        </div>
        <img alt="palmares nare sapins 2021" src={result_sapin21}/>
    </div>
);

const rose2020 = (
    <div className="result">
        <div className="title">
            Résultats de la NAVIGATION AUTOMOBILE Octobre rose 2020
        </div>
        <div className="text">
            Merci à tous pour votre participation et cette belle journée passée ensemble. Félicitations aux vainqueurs !
        </div>
        <img alt="palmares nare or 2020" src={result_or_20}/>
    </div>
);

const sapins2020 = (
    <div className="result">
        <div className="title">
            Résultats de la NAVIGATION AUTOMOBILE des Sapins 2020
        </div>
        <div className="text">
            Merci à tous pour votre participation et cette belle journée passée ensemble. Félicitations aux vainqueurs !
        </div>
        <img alt="palmares nare sapins 2020" src={result_sapins_2020}/>
    </div>
);

const formation2020 = (
    <div className="result">
        <div className="title">
            Résultats de la formation 2020
        </div>
        <div className="text">
            Merci à tous pour votre participation et cette belle journée passée ensemble. Félicitations aux vainqueurs !
        </div>
        <img alt="palmares nare formation 2020" src={result_formation20}/>
    </div>
);

const rose2019 = (
    <div className="result">
        <div className="title">
            Résultats de la 2eme NAVIGATION AUTOMOBILE Octobre rose
        </div>
        <div className="text">
            Merci à tous pour votre participation et cette belle journée passée ensemble. Félicitations aux vainqueurs !
        </div>
        <img alt="palmares nare octobre rose 2019" src={result_or19}/>
    </div>
);

const sapin2019 = (
    <div className="result">
        <div className="title">
            Résultats de la 1ere NAVIGATION AUTOMOBILE des Sapins
        </div>
        <div className="text">
            Merci à tous pour votre participation et cette belle journée passée ensemble. Félicitations aux vainqueurs !
        </div>
        <Document file={result_sapins_2019}>
            <Page scale={1.3} pageNumber={1} />
        </Document>
    </div>
);

const rose2018 = (
    <div className="result">

        <div className="title">
            Résultats de la 1ere NAVIGATION AUTOMOBILE Octobre rose
        </div>

        <div className="text">
            Merci à tous pour votre participation et cette belle journée passée ensemble. Félicitations aux vainqueurs !
        </div>

        <div className="table general">
            <div className="title">
                Classement général
            </div>
            <img alt="tableau resultat general" src={result_general1}/>
            <img alt="tableau resultat general" src={result_general2}/>
            <img alt="tableau resultat general" src={result_general3}/>
            <img alt="tableau resultat general" src={result_general4}/>
            <img alt="tableau resultat general" src={result_general5}/>
            <img alt="tableau resultat general" src={result_general6}/>
        </div>

        <div className="table women">
            <div className="title">
                Classement coupe des dames
            </div>
            <img alt="tableau resultat coupe dames" src={result_women}/>
        </div>

        <div className="table classic">
            <div className="title">
                Classement classique
            </div>
            <img alt="tableau resultat classique" src={result_classic1}/>
            <img alt="tableau resultat classique" src={result_classic2}/>
            <img alt="tableau resultat classique" src={result_classic3}/>
        </div>

        <div className="table modern">
            <div className="title">
                Classement moderne
            </div>
            <img alt="tableau resultat moderne" src={result_modern1}/>
            <img alt="tableau resultat moderne" src={result_modern2}/>
            <img alt="tableau resultat moderne" src={result_modern3}/>
            <img alt="tableau resultat moderne" src={result_modern4}/>
        </div>

    </div>
);

const palmaresItems = {
    "palmares": {
        "plaque": logo_nare,
        "alt_plaque": "Cup",
        "label": "Palmares general",
        "content": palmares
    },
    "gtl24": {
        "plaque": plaque_gtl24,
        "alt_plaque": "Plaque GTL 2024",
        "label": "GT Legens 2024",
        "content": gtl2024
    },
    "bdl24": {
        "plaque": plaque_bdl24,
        "alt_plaque": "Plaque BDL 2024",
        "label": "Ban de Laveline 2024",
        "content": bdl2024
    },
    "va24": {
        "plaque": plaque_va24,
        "alt_plaque": "Plaque VA 2024",
        "label": "Vosges Alsace 2024",
        "content": va2024
    },
    "bds24": {
        "plaque": plaque_bds24,
        "alt_plaque": "Plaque bds 2024",
        "label": "Ban de Sapt 2024",
        "content": bds2024
    },
    "cup23": {
        "plaque": plaque_cup23,
        "alt_plaque": "Plaque cup 2023",
        "label": "NARE Cup 2023",
        "content": cup2023
    },
    "or23": {
        "plaque": plaque_rose23,
        "alt_plaque": "Plaque Octobre rose 2023",
        "label": "Octobre rose 2023",
        "content": or2023
    },
    "leclerc23": {
        "plaque": plaque_leclerc23,
        "alt_plaque": "Plaque Leclerc 2023",
        "label": "E.Leclerc 2023",
        "content": leclerc2023
    },
    "6h23": {
        "plaque": plaque_6h23,
        "alt_plaque": "Plaque 6h 2023",
        "label": "6H Auto-Raon 2023",
        "content": sixh2023
    },
    "sapins23": {
        "plaque": plaque_sapins23,
        "alt_plaque": "Plaque sapins 2023",
        "label": "Sapins 2023",
        "content": sapins2023
    },
    "bdl23": {
        "plaque": plaque_bdl23,
        "alt_plaque": "Plaque bdl 2023",
        "label": "Ban de Laveline 2023",
        "content": bdl2023
    },
    "cup22": {
        "plaque": plaque_cup22,
        "alt_plaque": "Plaque cup 2022",
        "label": "NARE Cup 2022",
        "content": cup2022
    },
    "or22": {
        "plaque": plaque_rose22,
        "alt_plaque": "Plaque Octobre rose 2022",
        "label": "Octobre rose 2022",
        "content": or2022
    },
    "leclerc22": {
        "plaque": plaque_leclerc22,
        "alt_plaque": "Plaque Leclerc 2022",
        "label": "E.Leclerc 2022",
        "content": leclerc2022
    },
    "6h22": {
        "plaque": plaque_6h22,
        "alt_plaque": "Plaque 6h 2022",
        "label": "6H Auto-Raon 2022",
        "content": sixh2022
    },
    "sapins22": {
        "plaque": plaque_sapins22,
        "alt_plaque": "Plaque sapins 2022",
        "label": "Sapins 2022",
        "content": sapins2022
    },
    "bdl22": {
        "plaque": plaque_bdl22,
        "alt_plaque": "Plaque bdl 2022",
        "label": "Ban de Laveline 2022",
        "content": bdl2022
    },
    "sapins21": {
        "plaque": plaque_sapins21,
        "alt_plaque": "Plaque sapins 2021",
        "label": "Sapins 2021",
        "content": sapins2021
    },
    "or20": {
        "plaque": plaque_rose20,
        "alt_plaque": "Plaque octobre rose 2020",
        "label": "Octobre rose 2020",
        "content": rose2020
    },
    "sapins20": {
        "plaque": plaque_sapin20,
        "alt_plaque": "Plaque sapins 2020",
        "label": "Sapins 2020",
        "content": sapins2020
    },
    "formation20": {
        "plaque": plaque_formation20,
        "alt_plaque": "Plaque formation 2020",
        "label": "Formation 2020",
        "content": formation2020
    },
    "or19": {
        "plaque": plaque_rose19,
        "alt_plaque": "Plaque Octobre rose 2019",
        "label": "Octobre rose 2019",
        "content": rose2019
    },
    "sapins19": {
        "plaque": plaque_sapin,
        "alt_plaque": "Plaque sapins 2019",
        "label": "Sapins 2019",
        "content": sapin2019
    },
    "or18": {
        "plaque": plaque_rose18,
        "alt_plaque": "Plaque Octobre rose 2018",
        "label": "Octobre rose 2018",
        "content": rose2018
    },
}

class Result extends Component {

    constructor() {
        super();
        this.state = {
            result: palmares,
            resultId: 'palmares'
        }
    }

    changeTab(content, id, e) {
        e.preventDefault();
        this.setState({result: content});
        this.setState({resultId: id});
    }

    render() {
        return (
            <div className="nare">
                <div className="header">
                    <Menu />
                </div>

                <div className="body content">
                    <nav className="palmares-tab">
                        {(Object.entries(palmaresItems).reverse()).map((item, key) => (
                            <div
                                onClick={(e) => this.changeTab(item[1].content, item[0], e)}
                                className={this.state.resultId === item[0] ? 'selected tab-link' : 'tab-link'}>
                                <img alt={item[1].alt_plaque} src={item[1].plaque} /><br/>
                                <a>{item[1].label}</a>
                            </div>
                        ))}
                    </nav>

                    { this.state.result }
                </div>

                <Footer />
            </div>
        )
    }
}

export default Result
