import React, { Component } from 'react';
import logo from '../asset/logo-nare.png';
import logo_cup from '../asset/logo-nare-cup.jpg';
import logo_tava from '../asset/logo/logo-tava-big.jpg';
import logo_bar from '../asset/logo/logo_bar.jpg';
import './Menu.css';
import Logo from './Logo'
import { goToTop } from 'react-scrollable-anchor'
import { Link } from 'react-router-dom'
import roadbookPDF from '../asset/pdf/explication-roadbook-NARE.pdf'
import roadbookBaladePDF from '../asset/pdf/roadbook-balade.pdf'
import logo_tava_mob from '../asset/logo/logo-tava-mob.jpg'
import logo_fma from '../asset/logo/logo-fma.jpg'
import logo_gtl from '../asset/logo/logo-gtl.jpg'

class Menu extends Component {

    handleLogoClick() {
        goToTop()
    }

    render() {
        return (
            <div className="nare-menu-header-wrapper">
                <div className="nare-menu-header">
                    <div className="nare-nav">
                        <span>Cliquez sur les onglets</span>
                        <a href="/home"><Logo src={logo} className="nare-logo" alt="nare logo" onClick={this.handleLogoClick} /></a>
                        <a href="https://cup.nare.club"><Logo src={logo_cup} className="nare-logo" alt="nare cup logo" /></a>
                        <a href="https://auto-raon.club/"><Logo src={logo_tava} className="nare-logo" alt="nare cup logo" /></a>
                        <a href="https://mob.auto-raon.club/"><Logo src={logo_tava_mob} className="nare-logo" alt="mob tava logo" /></a>
                        <a href="https://fma.nare.club/"><Logo src={logo_fma} className="nare-logo" alt="fma logo" /></a>
                        <a href="https://gtl.nare.club/"><Logo src={logo_gtl} className="nare-logo" alt="gtl logo" /></a>
                        <Link to="/navigations"><span className={"light"}>Nos</span> navigations Auto</Link>
                        <a href="https://auto-raon.club/balades"><span className={"light"}>Nos</span> balades Auto</a>
                        <a href="https://mob.auto-raon.club"><span className={"light"}>Nos</span> balades Mob'Moto</a>
                        <a href="https://cup.nare.club">Championnat nare cup</a>
                        <a href="https://gtl.nare.club/">Roulage circuit</a>
                        <a href="https://fma.nare.club/">Festival mecanique anciennes beaulieu park</a>
                        <a href="https://www.gtlegends.fr/">Festival gt legends</a>
                        <a href={roadbookPDF}>Explications <span className={"light"}>d'une épreuve de navigation</span></a>
                        <a href={roadbookBaladePDF}>Explications <span className={"light"}>du roadbook</span> Balade auto et Mob'Moto</a>
                        <Link to="/result">Archives<span className={"light"}> du club et </span> palmares <span className={"light"}>de nos navigations</span></Link>
                        <Link to="/gallery">Galerie Photo</Link>
                        <Link to="/event"><span className={"light"}>Les</span> sorties <span className={"light"}>du club</span></Link>
                        <Link to="/sponsor">Nos sponsors</Link>
                        <Link to="/contact">Contact</Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default Menu
