import React, {Component} from "react";
import Footer from "./Footer";

import "./Gallery.css";
import Menu from "./Menu";

import GalleryNavBar from "./GalleryNavBar.jsx";

class Gallery extends Component{
    render() {
        return (
            <div className="nare">
                <div className="header">
                    <Menu/>
                </div>
                <div className="content">
                    <div className={"gallery"}>
                        <GalleryNavBar/>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default Gallery
