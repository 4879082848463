import React, { Component } from 'react';

import Request from '../ApiClient';
import { Link } from 'react-router-dom'

class ArticleList extends Component{
    constructor(props) {
        super(props);
        this.state = {
            articles: this.getList(this.props.page)
        };
    }

    getList(page) {
        this.setState({page: page});
        Request.get("/articleList?_format=json&page=" + page, '').then(
            response => {
                this.setState({articles: response});
            }
        )
    }

    rawMarkup(markup){
        return { __html: markup };
    }

    articleView(article) {

        var htmlInput = article.field_resume[0].value;
        var imageTeaser = article.field_image_teaser[0];
        var date  = article.created[0].value;
        var nid  = article.nid[0].value;
        var type = "news";
        var external = false;
        var path = "/article/" + nid;
        var link = "";
        var skip = false;

        // If Article other than news global or event redirect to associated site
        article.field_article_type.forEach((item) => {
            if (item.target_id === 1) {
                skip = true;
                return;
            } else if (item.target_id === 9) {
                skip = true;
                path = "https://cup.nare.club" + path;
                external = true;
            }
        })
        if (!skip) {
            if (article.field_article_type[0].target_id === 4) {
                path = "https://octobre-rose.nare.club" + path;
                external = true;
            } else if (article.field_article_type[0].target_id === 5) {
                path = "https://sapins.nare.club" + path;
                external = true;
            } else if (article.field_article_type[0].target_id === 6) {
                path = "https://gtlegends.nare.club" + path;
                external = true;
            } else if (article.field_article_type[0].target_id === 7) {
                path = "https://evenement.nare.club" + path;
                external = true;
            } else if (article.field_article_type[0].target_id === 9) {
                path = "https://cup.nare.club" + path;
                external = true;
            } else if (article.field_article_type[0].target_id === 10) {
                path = "https://bdl.nare.club" + path;
                external = true;
            } else if (article.field_article_type[0].target_id === 11) {
                path = "https://leclerc.nare.club" + path;
                external = true;
            }
        }

        if (!external)
            link = <Link to={path}>Voir plus</Link>;
        else
            link = <a href={path}>Voir plus</a>;

        // If type event change style
        if (article.field_article_type[0].target_id === 2) {
            type = "event";
        }

        if (imageTeaser === undefined || imageTeaser.url === undefined) {
            imageTeaser = '';
        }
        else {
            imageTeaser = <img alt={imageTeaser.alt !== undefined ? imageTeaser.alt : 'image'} src={imageTeaser.url} />
        }

        date = new Date(date);

        return(
            <div className={"article-teaser article-" + type}>
                {imageTeaser}
                <div className="article-content">
                    <h3>{article.title[0].value}</h3>
                    <div dangerouslySetInnerHTML={this.rawMarkup(htmlInput)}/>
                </div>
                <div className="link-more">
                    <div className="article-date">
                        {date.toLocaleDateString()}
                    </div>
                    {link}
                </div>
            </div>
        );
    }

    render() {
        var output = [];
        var i = 0;

        if (this.state.page !== this.props.page)
            this.getList(this.props.page);

        for (var article in this.state.articles) {
            i++;
            output.push(
                <div className="article">
                    {this.articleView(this.state.articles[article])}
                </div>
            );
            if (i < this.state.articles.length) {
                output.push(<div className="checkered"/>);
            }
        }

        return(output);
    }
}

export default ArticleList;