import React, { Component } from 'react';
import Pager from 'react-pager';

import './ArticleBlock.css';
import ArticleList from './ArticleList';
import Request from '../ApiClient';

class ArticleBlock extends Component {
    constructor() {
        super();
        this.state = {
            total:       0,
            current:     0,
            visiblePage: 3,
        };

        this.handlePageChanged = this.handlePageChanged.bind(this);
    }

    componentDidMount() {
        Request.get("/articleList-all?_format=json", '').then(
            response => {
                this.setState({total: Math.ceil(response.length/10)});
            }
        )
    }

    handlePageChanged(newPage) {
        this.setState({ current : newPage });
    }

    render() {
        var currentPage = this.state.current;

        return(
            <div className="article-block">

                <h2>Nos <span className={"blue"}>actualités</span> et <span className={"red"}>participations</span></h2>

                <ArticleList page={currentPage} />

                <Pager
                    total={this.state.total}
                    current={currentPage}
                    visiblePages={this.state.visiblePage}
                    titles={{ first: '<|', last: '>|' }}
                    className="pagination"
                    onPageChanged={this.handlePageChanged}
                />

            </div>
        );
    }
}

export default ArticleBlock;