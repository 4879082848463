import React, { Component } from 'react';
import "./Trials.css"
import "./App.css"
import Menu from "./section/MenuTrial"
import {Link} from "react-router-dom";
import TitleMap from "./section/Title";
import afficheBDL from "./asset/affiche/bdl.jpg";
import afficheBDS from "./asset/affiche/bds.jpg";
import afficheVosgesAlsace from "./asset/affiche/vosges-alsace.jpg";
import afficheOR from "./asset/affiche/octobre-rose.jpg";
import afficheSapins from "./asset/affiche/sapins.jpg";
import afficheGTL from "./asset/affiche/gtl.jpg";

class Trials extends Component {
    render() {
        const titleText = <div>
            <div className="black"><span className="rose">I</span>NSCRIPTION ET <span className="rose">R</span>églement
            </div>
            <div className="black">DE LA</div>
            <div className="black"><span className="rose">N</span>ARE <span className="rose">C</span>UP</div>
        </div>;

        return(
            <div className="nare">
                <div className="header">
                    <Menu />
                </div>
                <div className="content">
                    <TitleMap text={titleText} />

                    <div className="checkered-main"/>

                    <div className="subscribe">
                        <div className="description">
                            <p>Cliquez sur l'affiche d'une navigation pour accèder ou sur son lien dans le menu pour plus d'informations concernant la navigation</p>
                        </div>

                        <h2>Navigation automobile de Ban de sapt</h2>
                        <div className="alert">Cliquez sur l'affiche ci-dessous</div>
                        <p><a href="https://bds.nare.club"><img src={afficheBDS}/></a></p>

                        <h2>Navigation automobile Vosges Alsace</h2>
                        <div className="alert">Cliquez sur l'affiche ci-dessous</div>
                        <p><a href="https://vosges-alsace.nare.club"><img src={afficheVosgesAlsace}/></a></p>

                        <h2>Navigation automobile de Ban de laveline</h2>
                        <div className="alert">Cliquez sur l'affiche ci-dessous</div>
                        <p><a href="https://bdl.nare.club"><img src={afficheBDL}/></a></p>

                        <h2>Roulage circuit et Navigation historique du festival GT Legends</h2>
                        <div className="alert">Cliquez sur l'affiche ci-dessous</div>
                        <p><a href="https://gtl.nare.club"><img src={afficheGTL}/></a></p>

                        <h2>Navigation automobile des Sapins</h2>
                        <div className="alert">Cliquez sur l'affiche ci-dessous</div>
                        <p><a href="https://sapins.nare.club"><img src={afficheSapins}/></a></p>

                        <h2>Navigation automobile d'Octobre rose</h2>
                        <div className="alert">Cliquez sur l'affiche ci-dessous</div>
                        <p><a href="https://octobre-rose.nare.club"><img src={afficheOR}/></a></p>
                    </div>

                </div>
            </div>
        )
    }
}

export default Trials