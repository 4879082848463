//import React from 'react'

function get(path, params) {
    if (params !== 'absolute')
        path = `https://api.nare.club${path}`;
    return fetch(path, {
        accept: "application/json"
    }).then(checkStatus).then(parseJSON);
}

function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }
    const error = new Error(`HTTP Error ${response.statusText}`);
    error.status = response.statusText;
    error.response = response;
    console.log(error); // eslint-disable-line no-console
    throw error;
}

function parseJSON(response) {
    return response.json();
}

/*function getNext(response) {
    if (typeof response.links.next !== typeof undefined) {
        var nextLink = response.links.next;
        response.links.next = null;
        return response.then(concat(fetch(nextLink, {
           accept: "application/json"
        }).then(checkStatus).then(parseJSON).then(getNext)))
    }
}*/

const Request = {get}
export default Request