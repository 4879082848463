import React, { Component } from 'react';
import './App.css';
import './PassedEvents.css'
import Menu from './section/MenuPassed';
import Title from './section/Title';
import Footer from './section/Footer';
import { ParallaxProvider } from 'react-scroll-parallax';
import ScrollableAnchor from 'react-scrollable-anchor'
import { configureAnchors } from 'react-scrollable-anchor'
import sapin21 from './asset/affiche_passed/sapin0921.jpg'
import sapin20 from './asset/affiche_passed/sapin0420.jpg'
import sapin19 from './asset/affiche_passed/sapin0419.jpg'
import or20 from './asset/affiche_passed/or0920.jpg'
import or19 from './asset/affiche_passed/or1019.jpg'
import or18 from './asset/affiche_passed/or1018.jpg'
import formation20 from './asset/affiche_passed/formation0220.jpg'
import expo20 from './asset/affiche_passed/expo0920.jpg'
import expo21 from './asset/affiche_passed/expo0921.jpg'

configureAnchors({offset: -100, scrollDuration: 1000})

class PassedEvents extends Component {
    render() {
        return (
            <ParallaxProvider>
                <div className="nare">
                    <ScrollableAnchor id={'header'}>
                        <div className="header">
                            <Menu />
                        </div>
                    </ScrollableAnchor>
                    <div className="content">
                        <Title />

                        <div className="checkered-main"/>

                        <div className="passed-events">
                            <p><img src={expo21} alt="exposition Leclerc 2021"/></p>
                            <p><img src={sapin21} alt="sapin 2021"/></p>
                            <p><img src={or20} alt="octobre rose 2020"/></p>
                            <p><img src={expo20} alt="exposition 2020"/></p>
                            <p><img src={sapin20} alt="sapin 2020"/></p>
                            <p><img src={formation20} alt="formation 2020"/></p>
                            <p><img src={or19} alt="octobre rose 2019"/></p>
                            <p><img src={sapin19} alt="sapin 2019"/></p>
                            <p><img src={or18} alt="octobre rose 2018"/></p>
                        </div>

                    </div>
                    <ScrollableAnchor id={'joinus'}>
                        <Footer />
                    </ScrollableAnchor>
                </div>
            </ParallaxProvider>
        );
    }
}

export default PassedEvents;
